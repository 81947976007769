import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_ONE = new BigNumber(1);

export const Paths = {
  Landing: {
    Index: "/",
  },
};

export const PUSH_NOTIFICATION_STORAGE_KEY = "push-notification-register-status";
export const REGISTERED = "REGISTERED";

export const LOGIN_URL = "https://a.ntrk2.com/3a09d84f-d543-4564-b4bc-fd6b1b68d462";
export const REGISTER_URL = "https://a.ntrk2.com/dd1ad935-a1de-427e-9af2-38aa56b8929c";

export const LOGIN_URL_MAP = {
  "www-hk39play.com": "https://a.ntrk2.com/e8847a3a-0f50-42cd-be69-748fb5c7ab26",
};

export const REGISTER_URL_MAP = {
  "www-hk39play.com": "https://a.ntrk2.com/55164940-4da6-4506-a354-6f683f9a656d",
};

export const FLOATING_BANNER_URL = "http://hk399thai.com/cs";

export * from "./codes";
export * from "./context";
