import { VisuallyHidden } from "@99tech/ui";
import { Box, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext } from "react";
import { Banks, Bg, BtnLogin, BtnRegister, Chars, Head1, Head2, Logo, Providers } from "src/assets";
import { FloatingBanner } from "src/main/components";
import { AppContext } from "src/main/constants";
import { createStyles, isMobile } from "src/main/utils";

interface LandingPageProps extends React.PropsWithChildren {}

const LandingPage: React.FC<LandingPageProps> = (props) => {
  const { urls } = useContext(AppContext);
  const { loginUrl, registerUrl } = urls ?? {};

  const handleClick = useCallback(
    (type: "login" | "register") => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      if (type === "login") {
        window.open(loginUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      } else {
        window.open(registerUrl, "_blank", isMobile() ? "width=full,height=full" : "");
      }
    },
    [loginUrl, registerUrl],
  );

  return (
    <Box sx={styles.root}>
      <VisuallyHidden as="h1">HK39</VisuallyHidden>
      <Stack
        direction="column"
        alignItems="center"
        width="100%"
        textAlign="center"
      >
        <img
          alt="head-1"
          src={Head1}
          style={{ width: 320 }}
        />

        <img
          alt="head-2"
          src={Head2}
          style={{ width: 206 }}
        />

        <img
          alt="logo"
          src={Logo}
          style={{ width: 240 }}
        />

        <Stack
          direction="row"
          gap={2}
          mt={-0.5}
        >
          <a
            href={loginUrl}
            onClick={handleClick("login")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="btn-login"
              src={BtnLogin}
              style={{ width: 140 }}
            />
          </a>
          <a
            href={registerUrl}
            onClick={handleClick("register")}
            target="_blank"
            rel="noreferrer"
          >
            <img
              alt="btn-register"
              src={BtnRegister}
              style={{ width: 140 }}
            />
          </a>
        </Stack>

        <img
          alt="chars"
          src={Chars}
          style={{ width: 360 }}
        />

        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          gap={1}
          width="100%"
        >
          <Typography
            fontSize={7}
            fontWeight={400}
          >
            HK39 ระบบใหม่เริ่มต้นง่ายให้ค่าตอบแทนสูง slot แหล่งรวมเกมพนันชั้นยอด bet ลงทุนได้ทุกบาทไม่มีขั้นต่ำ
            เครดิตฟรี มอบให้กับสมาชิกทุกคนก่อนเริ่มเกมส์ wallet ทำธุรกรรมกับระบบใหม่ง่ายกว่าฝากถอนไม่เสียค่าธรรมเนียม
            กำหนดรายรับรายจ่ายภายในตัวเกมได้อย่างแม่นยำ โอกาสทำกำไรเพิ่มขึ้นอีกหลายเท่า รับรองไม่ผิดหวังหากต้องการทำกำไร
            และหารายได้เสริมระหว่างช่วงวันหยุด พร้อมสนับสนุนสมาชิกใหม่ให้เริ่มได้ดีมากขึ้นกว่าเดิม
            ขั้นตอนการลงทะเบียนไม่ซับซ้อนทำความเข้าใจเพียงเล็กน้อยHK39 มีทุนจำกัดเริ่มได้ทุกเกม
            หากคุณไม่อยากพลาดโอกาสทำเงินอย่างง่าย รีบสมัครใช้บริการได้เลยอนุมัติเร็ว
            ระบบที่มีมาตรฐานทำให้การใช้งานราบรื่นไม่มีสะดุด ใช้งานได้ทุกอุปกรณ์ไร้ปัญหากวนใจ
          </Typography>

          <img
            alt="logo"
            src={Logo}
            style={{ width: 140 }}
          />

          <Typography
            fontSize={7}
            fontWeight={400}
          >
            HK39 แหล่งรวมเกมสล็อตออโต้ ที่ครบครันมากที่สุดในปี 2024 เว็บไซต์ที่ให้บริการเกมสล็อตออโต้
            ที่ครบครันมากที่สุด ซึ่งเปิดให้บริการเกมสล็อตออนไลน์มากกว่า 50 ค่ายดัง จากต่างประเทศตัวโลก
            รวมไปถึงค่ายเกมดังอย่าง HK39 ที่พร้อมอัปเดตเกมสล็อตใหม่ๆ อยู่ตลอดเวลา เดิมพันกับ HK39
            ผู้เล่นทุกท่านจะได้สัมผัสกับประสบการณ์การเดิมพันที่ครบวงจรมากที่สุด สะดวกที่สุด และรวดเร็วมากที่สุด
            พร้อมการให้บริการผ่านเว็บตรงที่มีคุณภาพสูง
            สามารถเข้าเดิมพันผ่านมือถือได้ทุกที่ทุกเวลาครบจบในที่เดียวได้แล้วตอนนี้
          </Typography>

          <img
            alt="providers"
            src={Providers}
            style={{ width: 320 }}
          />

          <Typography
            fontSize={7}
            fontWeight={400}
          >
            ทดลองเล่น HK39 เล่นฟรี ไม่เสียเงิน ทดลองเล่น HK39
            เป็นระบบที่ช่วยให้ผู้เล่นสามารถเข้าเดิมพันได้เลยโดยไม่ต้องเสียเงิน
            หรือเรียกได้ว่าใช้เครดิตฟรีในการเข้าเดิมพัน
            ซึ่งเราได้ออกแบบระบบมาเพื่อให้ในการทดลองเล่นสล็อตเท่านั้นเครดิตเริ่นต้นจะอยู่ที่ 10,000 บาท
            สามารถเข้าเล่นได้ทุกเกม ทุกค่าย และยังสามารถทดลองซื้อฟรีสปินได้อีกด้วย
            ซึ่งทางเว็บไซต์ได้สงวนสิทธิ์เฉพาะสมาชิก HK39 VIP
          </Typography>

          <img
            alt="banks"
            src={Banks}
            style={{ width: 340 }}
          />
        </Stack>
      </Stack>

      <FloatingBanner />
    </Box>
  );
};

const styles = createStyles({
  root: {
    backgroundImage: `url(${Bg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% auto",
    backgroundPosition: "top",
    backgroundColor: "#03103E",

    p: "16px",
    pt: "24px",
    pb: "100px",
    mx: "-16px",

    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    gap: "0px",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    overflowX: "hidden",
    color: "#fff",
  },
  images: {
    zIndex: 10,
    position: "relative",
    width: "360px",
    height: "324px",
    img: {
      position: "absolute",
    },
  },
  footer: {
    zIndex: 0,
    position: "fixed",
    bottom: "0",
    width: "100vw",
    height: "200px",
    flexShrink: 0,
    background: "radial-gradient(800px 100% at 50% 100%, #FFF9C1 0%, #21966C 19%, rgba(0, 0, 0, 0.00) 50%)",
  },
});

export default LandingPage;
